<template>
  <div class="section-map">
    <div id="section-map-anchor"></div>
    <!-- <div class="bg one"></div>
    <div class="bg two"></div> -->
    <div class="title"></div>
    <div class="maps-pics">
      <ul>
        <li>
          <div>
            <figure>
              <Image :src="require('@/assets/img/section-map/SectionMap_Picture1.png')" alt="Image" preview />
              <figcaption></figcaption>
            </figure>
            <figure>
              <Image :src="require('@/assets/img/section-map/SectionMap_Picture2.png')"  alt="Image" preview />
              <figcaption></figcaption>
            </figure>
          </div>
          <div>
            <figure>
              <Image :src="require('@/assets/img/section-map/SectionMap_Picture3.png')"  alt="Image" preview />
              <figcaption></figcaption>
            </figure>
          </div>
        </li>
        <li>
          <figure>
            <Image :src="require('@/assets/img/section-map/SectionMap_Picture4.png')"  alt="Image" preview />
            <figcaption></figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <Image :src="require('@/assets/img/section-map/SectionMap_Picture5.png')"  alt="Image" preview />
            <figcaption></figcaption>
          </figure>
          <figure>
            <Image :src="require('@/assets/img/section-map/SectionMap_Picture6.png')"  alt="Image" preview />
            <figcaption></figcaption>
          </figure>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  /* eslint-disable */
  name: 'SectionMap'
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .section-map {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 10.692vw;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-image: url('@/assets/img/section-twitter/SectionTwitter_Background.png');

    #section-map-anchor{
      position: absolute;
      margin-top: -13.471vw;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }

    // .bg {
    //   position: absolute;
    //   width: 100%;
    //   height: auto;
    //   z-index: -1;
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   background-size: contain;
    //   background-attachment: fixed;

    //   &.one {
    //     left: 0;
    //     top: 11.53vw;
    //     height: 93.524vw;
    //     background-image: url('@/assets/img/section-map/SectionTwitter_Background-2.png');
    //   }

    //   &.two {
    //     right: -35vw;
    //     top: 82.53vw;
    //     width: 107%;
    //     height: 117.278vw;
    //     background-image: url('@/assets/img/section-map/SectionPost_Background.png');
    //   }
    // }

    .title {
      width: 90vw;
      height: 20.126vw;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url('@/assets/img/section-map/SectionMap_Title.png');
    }

    .maps-pics {
      width: auto;
      height: auto;
      padding-top: 5.87vw;
      margin: 0 11.74vw;

      ul {
        li {
          width: 100%;
          display: flex;
          align-items: center;
          height: auto;


          figure {
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;

            figcaption {
              width: 35.333vw;
              position: absolute;
              height: 13.867vw;
              bottom: 0.933vw;
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              z-index: 0;
            }
          }

          & + li {
            margin-top: 1.468vw;
          }

          &:nth-of-type(1) {
            justify-content: space-between;

            & > div {
              display: flex;
              width: 37.526vw;
              box-sizing: border-box;
              height: 76.52vw;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;

              &:nth-of-type(1) {
                figure {
                  height: 37.526vw;

                  &:nth-of-type(1) {
                    figcaption {
                      background-image: url('@/assets/img/section-map/SectionMap_Name1.png');
                    }
                  }

                  &:nth-of-type(2) {
                    figcaption {
                      background-image: url('@/assets/img/section-map/SectionMap_Name2.png');
                    }
                  }
                }
              }

              &:nth-of-type(2) {
                figure {
                  height: inherit;

                  figcaption {
                    background-image: url('@/assets/img/section-map/SectionMap_Name3.png');
                  }
                }
              }
            }
          }

          &:nth-of-type(2) {
            & > figure {
              justify-content: flex-end;
              align-items: flex-end;
              height: 37.526vw;

              figcaption {
                bottom: 2vw;
                background-image: url('@/assets/img/section-map/SectionMap_Name4.png');
              }
            }
          }

          &:nth-of-type(3) {
            display: flex;
            align-items: center;
            justify-content: space-between;

            figure {
              width: 37.526vw;
              height: 37.526vw;

              &:nth-of-type(1) {
                figcaption {
                  background-image: url('@/assets/img/section-map/SectionMap_Name5.png');
                }
              }

              &:nth-of-type(2) {
                figcaption {
                  background-image: url('@/assets/img/section-map/SectionMap_Name6.png');
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
