<template>
  <div id="footer">
    <dl>
      <dt></dt>
      <dd>
        <article>
          <p>タイトル：暁ノ天刃録</p>
          <p>ジャンル：壮麗を極めた東方幻想RPG </p>
          <p>価格：基本プレイ無料(アプリ内課金あり) </p>
          <p>対象OS：iOS 9.0以降 / Android 5.0以降 </p>
          <p>配信日：2022年夏予定</p>
        </article>
      </dd>
    </dl>
    <ul class="sponsor">
      <li></li>
      <li></li>
    </ul>
    <article class="terms">
      <p>
        <a href="https://sdk-web.eyougame.com/doc/policy-jp/">利用規約</a>｜
        <a href="http://sdk-web.eyougame.com/doc/policy-jp/?tab=1">プライバシーポリシー</a>
      </p>
      <p>Copyright ©タビビトゲームス株式会社 ©EYOUGAME株式会社. All Rights Reserved.</p>
      <p>お問い合わせ：support@tabibitogames.com</p>
    </article>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  /* eslint-disable */
  name: 'Footer',
  data () {
    return {}
  }
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  #footer {
    height: 96.809vw;
    background: url('@/assets/img/footer/Footer_Ink.png') center bottom/contain no-repeat;
    margin-top: 14.078vw;
    box-sizing: content-box;
    border-bottom: 28vw solid #000;

    article {
      p {
        color: #000;
        text-align: left;
        font-weight: 700;
        line-height: 1;
      }
    }

    dl {
      padding: 0 7.547vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: auto;

      dt {
        width: 24.319vw;
        height: 24.319vw;
        background: url('@/assets/img/footer/Footer_GameIcon-2.png') center bottom/contain no-repeat;

        & + dd {
          margin-left: 4.612vw;
        }
      }

      dd {
        width: auto;
        height: auto;
        margin-top: 1.8vw;

        p{
          font-size: 2.516vw;
        }
      }
    }

    .sponsor {
      padding: 0 9.015vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: auto;
      margin-top: 6.499vw;

      li {
        &:nth-of-type(1) {
          width: 36.059vw;
          height: 6.918vw;
          background: url('@/assets/img/footer/Footer_TabibitoGameLogo.png') center/contain no-repeat;
        }

        &:nth-of-type(2) {
          width: 35.010vw;
          height: 8.176vw;
          background: url('@/assets/img/footer/Footer_EYOULogo.png') center/contain no-repeat;
        }
      }
    }

    .terms {
      margin-top: 6.499vw;
      padding: 0 9.434vw;

      p {
        font-size: 2.306vw;

        a{
          color: #000;
        }
      }
    }
  }
}
</style>
