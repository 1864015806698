<template>
  <div class="section-story">
    <div class="title"></div>
    <div class="story-box">
      <div class="des"></div>
      <div class="video">
        <div class="fluidMedia">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/MyVOvEpWAsY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  /* eslint-disable */
  name: 'SectionStory'
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .section-story {
    position: relative;
    height: auto;
    padding-bottom: 17.191vw;
    background: url('@/assets/img/section-story/SectionBackground.png') bottom/contain no-repeat;

    .title {
      width: 90vw;
      height: 20.126vw;
      margin: 0 auto;
      background: url('@/assets/img/section-story/SectionTitle.png') center/contain no-repeat;
    }

    .story-box {
      width: auto;
      height: auto;
      padding-top: 5.870vw;

      .des {
        width: 80.922vw;
        height: 90.985vw;
        background: url('@/assets/img/section-story/SectionWord.png') center/contain no-repeat;
        margin: 0 auto;
      }

      .video {
        width: 81.551vw;
        height: 46.960vw;
        background: url('@/assets/img/section-story/SectionMV.png') center/contain no-repeat;
        margin: 7.547vw auto 0;

        .fluidMedia {
          position: relative;
          padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
          height: 0;
          overflow: hidden;
        }

        .fluidMedia iframe {
          position: absolute;
          top: 0; 
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
