import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/scss/index.scss'

import PrimeVue from 'primevue/config'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import InputText from 'primevue/inputtext'
import Menubar from 'primevue/menubar'
import Carousel from 'primevue/carousel'
import Image from 'primevue/image'
import Button from 'primevue/button'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Galleria from 'primevue/galleria'

const app = createApp(App)

app.use(router)
app.use(PrimeVue)
app.component('InputText', InputText)
/* eslint-disable */
app.component('Menubar', Menubar)
/* eslint-disable */
app.component('Carousel', Carousel)
app.component('Image', Image )
app.component('Button', Button)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Galleria', Galleria)

app.mount('#app')
