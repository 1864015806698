<template>
  <div class="section-twitter">
    <!-- <div class="bg">
      <img :src="require('@/assets/img/section-twitter/SectionTwitter_Background-2.png')" />
    </div> -->
    <div class="title"></div>
    <div class="twitter-frame">
      <div class="twitter-box">
        <a class="twitter-timeline" href="https://twitter.com/Tenjinroku?ref_src=twsrc%5Etfw">Tweets by Tenjinroku</a>
      </div>
    </div>
    <div class="button" @click="gotoContact()"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  /* eslint-disable */
  name: 'SectionTwitter',

  setup () {
    const gotoContact = () => {
      window.open('https://twitter.com/Tenjinroku?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Eprofile%3ATenjinroku%7Ctwgr%5EeyJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3NlbnNpdGl2ZV9tZWRpYV9pbnRlcnN0aXRpYWxfMTM5NjMiOnsiYnVja2V0IjoiaW50ZXJzdGl0aWFsIiwidmVyc2lvbiI6bnVsbH0sInRmd190d2VldF9yZXN1bHRfbWlncmF0aW9uXzEzOTc5Ijp7ImJ1Y2tldCI6InR3ZWV0X3Jlc3VsdCIsInZlcnNpb24iOm51bGx9fQ%3D%3D&ref_url=https%3A%2F%2Fcreative-narwhal-fbb8fb.netlify.app%2F');
    }
  
    return { gotoContact }
  }
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .section-twitter {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 15.514vw;
    overflow: hidden;

    // img {
    //   width: 100%;
    //   height: 100%;
    // }

    // .bg {
    //   position: absolute;
    //   width: 100%;
    //   height: auto;
    //   z-index: -1;
    //   left: 0;
    //   top: 0;
    //   // height: 90.566vw;
    // }

    .title {
      width: 90vw;
      height: 20.126vw;
      margin: 0 auto;
      background: url('@/assets/img/section-twitter/SectionTwitter_Title.png')
        center/contain no-repeat;
    }

    .twitter-frame {
      width: 76.73vw;
      height: 85.325vw;
      margin: 3.564vw auto 7.338vw;
      padding: 2.5vw 4.2vw;
      box-sizing: border-box;
      background: #fff url('@/assets/img/section-twitter/SectionTwitter_BlockOn.png')
        center/cover no-repeat;

        .twitter-box {
          width: 100%;
          height: 100%;
          overflow-y: auto;
        }
    }

    .button {
      width: 36.688vw;
      height: 10.482vw;
      margin: 0 auto;
      background: url('@/assets/img/section-twitter/SectionTwitter_Button.png')
        center/contain no-repeat;
    }
  }
}
</style>
