<template>
  <div class="section-reserve">
    <div id="section-reserve-anchor"></div>
    <div class="title"></div>
    <div class="bonus-one"></div>
    <ul class="block-list fade-in">
      <li>
        <img src="@/assets/img/section-reserve/SectionReserveSpecial1_Block3w.png" alt="">
        <div :class="['stamp', stamp3 ? 'show' : '']"></div>
      </li>
      <li>
        <img src="@/assets/img/section-reserve/SectionReserveSpecial1_Block6w.png" alt="">
        <div :class="['stamp', stamp6 ? 'show' : '']"></div>
      </li>
      <li>
        <img src="@/assets/img/section-reserve/SectionReserveSpecial1_Block10w.png" alt="">
        <div :class="['stamp', stamp10 ? 'show' : '']"></div>
      </li>
    </ul>
    <ul class="block-list" >
      <li class="fade-in">
        <img src="@/assets/img/section-reserve/SectionReserveSpecial1_Block15w.png" alt="">
        <div :class="['stamp', stamp15 ? 'show' : '']"></div>
      </li>
      <li class="fade-in">
        <img src="@/assets/img/section-reserve/SectionReserveSpecial1_Block20w.png" alt="">
        <div :class="['stamp', 'w20', stamp20 ? 'show' : '']"></div>
      </li>
    </ul>
    <div class="store-login-beforehead">
      <img src="@/assets/img/section-reserve/SectionReserveSpecial1_SubTitle.png" alt="">
    </div>
    <div :class="['device', androidType ? 'android' : 'apple']"></div>
    <div class="bonus-two">
      <div class="reward a fade-in"></div>
      <div class="reward b fade-in"></div>
      <div class="reward c fade-in"></div>
    </div>
    <div class="bonus-three">
      <div class="reward fade-in"></div>
    </div>
    <Button class="reserve-btn" @click="gotoContact()"></Button>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'

export default defineComponent({
  /* eslint-disable */
  name: 'SectionReserve',
  setup () {
    const device = ref(null)
    let androidType = ref(true)
    let stamp3 = ref(null)
    let stamp6 = ref(null)
    let stamp10 = ref(null)
    let stamp15 = ref(null)
    let stamp20 = ref(null)

    let fadeInEls = []

    const getUserAgent = () => {
      device.value = navigator.userAgent.toLowerCase()
      // console.log('device =>', device.value)
      androidType.value = device.value.indexOf('android') > 0
    }

    const gotoContact = () => {
      window.open('https://apijp.eyougame.com/preheat/twitter_reg.php?gameId=6012100', '_blank');
    }

    const isElemVisible = (el) => {
      var rect = el.getBoundingClientRect()
      var elemTop = rect.top + 300 // 200 = buffer
      var elemBottom = rect.bottom
      return elemTop < window.innerHeight && elemBottom >= 0
    }

    const onScroll = (evt) => {
      for (let i = 0; i < fadeInEls.length; i++) {
        let elem = fadeInEls[i]
        if (isElemVisible(elem)) {
          elem.style.opacity = '1'
          elem.style.transform = 'scale(1)'
          fadeInEls.splice(i, 1) // only allow it to run once
        }
      }
    }

    onMounted(() => {
      getUserAgent()
      stamp3.value = !!Number(process.env.VUE_APP_SHOW_STAMP3)
      stamp6.value = !!Number(process.env.VUE_APP_SHOW_STAMP6)
      stamp10.value = !!Number(process.env.VUE_APP_SHOW_STAMP10)
      stamp15.value = !!Number(process.env.VUE_APP_SHOW_STAMP15)
      stamp20.value = !!Number(process.env.VUE_APP_SHOW_STAMP20)

      fadeInEls = Array.from(document.getElementsByClassName('fade-in'))
      document.addEventListener("scroll", onScroll)
      onScroll()
      // console.log('fadeInEls -->', fadeInEls);
    })

    onUnmounted(() => {
      document.removeEventListener('scroll', onScroll)
    })

    return {
      device,
      androidType,
      gotoContact,
      stamp3,
      stamp6,
      stamp10,
      stamp15,
      stamp20
    }
  }
})
</script>

<style lang="scss" scoped>
.section-reserve {
  .stamp {
    top: 0;
    position: absolute;
    width: 21.7vw;
    height: 16.1vw;
    background: url('@/assets/img/section-reserve/AchieveIcon.png') center/contain no-repeat;
    display: none;

    &.show {
      display: block;
    }
  }
}
@media screen and (max-width: 960px) {
  .section-reserve {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 9.224vw;
    background: url('@/assets/img/section-reserve/SectionReserve_Background.png') center -45.5vw/contain no-repeat;

    #section-reserve-anchor{
      position: absolute;
      margin-top: -13.471vw;
      width: 100%;
    }
    
    .title {
      width: 90vw;
      height: 30.608vw;
      margin: 0 auto;
      background: url('@/assets/img/section-reserve/SectionReserve_Title.png') center/contain no-repeat;
    }

    .fade-in {
              opacity: 0;
        transition: 0.3s all ease-out;
        transform: scale(0.8);
        box-sizing: border-box;
    }

    .bonus-one {
      // width: 85.744vw;
      height: 24.17vw;
      width: 100%;
      margin: 14.5vw auto 0;
      background: url('@/assets/img/section-reserve/SectionReserveSpecial1_Title.png') center/contain no-repeat;
    }

    .block-list {
      width: 89.308vw;
      height: auto;
      margin: 4.757vw auto 11.950vw;
      display: flex;

      li {
        position: relative;

        img {
          width: 100%;
          height: auto;
        }
      }

      &:nth-of-type(1) {
        justify-content: space-between;
        margin-bottom: 3.867vw;

        li {
          width: 29.333vw;

          &+li {
            margin-left: 0.933vw;
          }

          .stamp {
            left: 3.5vw;
            top: 12.5vw;
          }
        }
      }

      &:nth-of-type(2) {
        margin-top: 0;     
        flex-direction: column;

        li {
          width: 100%;

          .stamp {
            right: 9vw;
            top: 6vw;

            &.w20 {
              top: 11vw;
            }
          }
        }
      }
    }

    .store-login-beforehead {
      width: 59.958vw;
      margin: 0 auto 3.3vw;

      img {
        width: 100%;
        height: auto;
      }
    }

    .device {
      width: 57.862vw;
      height: 16.562vw;
      margin: 0 auto;

      &.android {
        background: url('@/assets/img/section-reserve/SectionReserveSpecial1_GoogleLogin.png') center/contain no-repeat;
      }

      &.apple {
        background: url('@/assets/img/section-reserve/SectionReserveSpecial1_IosLogin.png') center/contain no-repeat;
      }
    }

    .bonus-two {
      position: relative;
      width: 100%;
      height: 121.384vw;
      margin: 0 auto;
      background: url('@/assets/img/section-reserve/SectionReserveSpecial2.png') center/contain no-repeat;

      .reward {
        position: absolute;

        &.a {
          width: 30.267vw;
          height: 32.133vw;
          left: 3.867vw;
          top: 38vw;
          background: url('@/assets/img/section-reserve/SectionReserveSpecial2Gift1.png') center/contain no-repeat;
        }
        
        &.b {
          width: 30.267vw;
          height: 32.133vw;
          left: 35.867vw;
          top: 59vw;
          background: url('@/assets/img/section-reserve/SectionReserveSpecial2Gift2.png') center/contain no-repeat;
        }
        
        &.c {
          width: 30.267vw;
          height: 32.133vw;
          left: 67.5vw;
          top: 40vw;
          background: url('@/assets/img/section-reserve/SectionReserveSpecial2Gift3.png') center/contain no-repeat;
        }
      }
    }

    .bonus-three {
      width: 100%;
      height: 136.04vw;
      margin: -17.5vw auto 0;
      background: url('@/assets/img/section-reserve/SectionReserveSpecial3.png') center/cover no-repeat;
      z-index: -1;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .reward {
        position: absolute;
        width: 86.267vw;
        height: 36.4vw;
        left: 50%;
        top: 56.7vw;
        margin-left: calc(-86.267vw / 2);
        background: url('@/assets/img/section-reserve/SectionReserveSpecial3Gift.png') center/contain no-repeat;
      }
    }

    button.reserve-btn {
      border-width: 0px;
      position: absolute;
      width: 59.329vw;
      height: 17.820vw;
      background: url('@/assets/img/section-reserve/SectionReserveSpecial3_TwitterLogin.png') center/cover no-repeat;
      z-index: 1;
      left: 50%;
      bottom: 18.841vw;
      margin-left: calc(-59.329vw/2)
    }
  }
}
</style>
