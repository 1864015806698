<template>
  <div class="section-system">
    <div id="section-system-anchor"></div>
    <Carousel :value="products" :responsiveOptions="responsiveOptions" circular>
      <template #header>
        <div class="title"></div>
        <div class="video">
          <div class="fluidMedia">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/WnQKvoE77Vc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </template>
      <template #item="slotProps">
        <div class="product-item">
          <div class="product-item-content">
            <div class="mb-3">
              <img :src="require(`@/assets/img/section-system/${slotProps.data.image}`)" class="product-image" />
            </div>
          </div>
        </div>
      </template>
    </Carousel>
  </div>
  <i></i>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  /* eslint-disable */
  name: 'SectionSystem',
  setup () {
    onMounted(() => {
      products.value = [
        {image: "picture_1.jpg"},
        {image: "picture_2.jpg"}
        // {"image": "SectionSystem_Picture.png"}
      ]
    })
    const products = ref(null)
    const responsiveOptions = ref([
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '600px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '480px',
        numVisible: 1,
        numScroll: 1
      }
    ])

    return { products, responsiveOptions }
  }
})
</script>

<style lang="scss" scoped>
  .section-system {
    height: auto;
    padding-bottom: 9.644vw;

    #section-system-anchor{
      position: absolute;
      margin-top: -13.471vw;
      width: 100%;
    }

    .title {
      width: 90vw;
      height: 20.126vw;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url('@/assets/img/section-system/SectionSystem_Title.png');
    }

    .video {
      width: 81.551vw;
      height: 46.960vw;
      background: url('@/assets/img/section-story/SectionMV.png') center/contain no-repeat;
      margin: 7.547vw auto 7.547vw;

      .fluidMedia {
        position: relative;
        padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
        height: 0;
        overflow: hidden;
      }

      .fluidMedia iframe {
        position: absolute;
        top: 0; 
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .product-item {
    .product-item-content {
      border-radius: 3px;
      text-align: center;

      .p-carousel-prev {
        position: absolute;
        bottom: 0;
      }
    }

    .product-image {
      width: 100%;
    }
  }

  i {
    background-image: url('@/assets/img/section-system/picture_1.jpg');
    background-image: url('@/assets/img/section-system/picture_1.jpg');
  }
</style>
