<template>
  <div class="section-job">
    <div id="section-job-anchor"></div>
    <div class="title"></div>
    <TabView ref="tabview2" v-model:activeIndex="active" :tab-change="enterCharct()" >
      <TabPanel v-for="tab in jobsArr" :key="tab.name">
        <JobTransAni :charct="tab"></JobTransAni>
      </TabPanel>
    </TabView>
    <div class="py-2">
      <Button v-for="tab, i in jobsArr" :key="tab.name" @click="leaveCharct(i, 'clickBtn')" :class="[active === i ? 'active' : '' ]" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import JobTransAni from './SectionJob/JobTransAni.vue'

export default defineComponent({
  /* eslint-disable */
  name: 'SectionJob',
  components: {
    JobTransAni
  },
  setup() {
    const active = ref(0)
    let timer = ref(null)
    const jobsArr = ref([
      { 
        id: 1,
        name: 'SwordsMan',
        active: true,
        fadeOut: false,
        dir: 'toLeft',
        videoSrc: 'https://www.youtube.com/embed/uODJ35YXUS4'
      },
      { 
        id: 3,
        name: 'SwordsWoman',
        active: false,
        fadeOut: false,
        dir: 'toRight',
        videoSrc: 'https://www.youtube.com/embed/F0A41vpBsGQ'
      },
      { 
        id: 2,
        name: 'MageMan',
        active: false,
        fadeOut: false,
        dir: 'toLeft',
        videoSrc: 'https://www.youtube.com/embed/Lu4w5n7h4cg'
      },
      { 
        id: 4,
        name: 'MageWoman',
        active: false,
        fadeOut: false,
        dir: 'toRight',
        videoSrc: 'https://www.youtube.com/embed/LObtchgOJaw'
      },
      { 
        id: 5,
        name: 'MusicMan',
        active: false,
        fadeOut: false,
        dir: 'toLeft',
        videoSrc: 'https://www.youtube.com/embed/DZ1uhCWkrE8'
      },
      { 
        id: 7,
        name: 'MusicWoman',
        active: false,
        fadeOut: false,
        dir: 'toRight',
        videoSrc: 'https://www.youtube.com/embed/jrNL9qJkrCE'
      },
      { 
        id: 6,
        name: 'FightingMan',
        active: false,
        fadeOut: false,
        dir: 'toLeft',
        videoSrc: 'https://www.youtube.com/embed/eMNT2p6IbJc'
      },
      { 
        id: 8,
        name: 'FightingWoman',
        active: false,
        fadeOut: false,
        dir: 'toRight',
        videoSrc: 'https://www.youtube.com/embed/OGgigAVaUpg'
      }
    ])

    const responsiveOptions = ref([
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '600px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '480px',
        numVisible: 1,
        numScroll: 1
      }
    ])

    const leaveCharct = (idx, type) => {
      jobsArr.value.forEach(e => e.fadeOut = false)
      if (active.value !== idx) {
        jobsArr.value.find((e, i) => i === active.value).fadeOut = true
        setTimeout(() => {
          active.value = idx
        }, 300)
      }

      if (type === 'clickBtn') {
        clearInterval(timer)
        autoPlay()
      }
    }
    
    const enterCharct = () => {
      setTimeout(() => {
        jobsArr.value.forEach(e => e.active = false)
        jobsArr.value.find((e, i) => i === active.value).active = true
      }, 10)
    }

    const autoPlay = () => {
      timer = setInterval(() => {
        let n = active.value
        n < (jobsArr.value.length - 1) ? n++ : n = 0
        leaveCharct(n, 'autoplay')
      }, 5000)
    }

    onMounted(() => {
      jobsArr.value.forEach((e, i) => e.active && (active.value = i))
      autoPlay()
    })

    return { 
      jobsArr,
      responsiveOptions,
      active,
      enterCharct,
      leaveCharct
    }
  }
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .section-job {
    height: auto;
    padding-bottom: 17.191vw;

    #section-job-anchor{
      position: absolute;
      margin-top: -13.471vw;
      width: 100%;
    }

    .title {
      width: 90vw;
      height: 20.126vw;
      margin: 0 auto;
      background: url('@/assets/img/section-job/SectionJob_Title.png') center/contain no-repeat;
    }
  }

  .p-button {
    border-radius: 0;
    width: 10.482vw;
    height: 15.304vw;
    border-width: 0;
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-color: transparent !important;

    & + .p-button {
      margin-left: 0.92vw;
    }

    &:nth-of-type(1), &:nth-of-type(1):enabled:hover {
      background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsMan.png'); 
      &::before {
        content: '';
        background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsMan_Hover.png');
      }

      &.active {
        background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsMan_Hover.png');
      }
    }

    &:nth-of-type(2), &:nth-of-type(2):enabled:hover {
      background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsWoman.png');
      &::before {
        content: '';
        background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsWoman_Hover.png');
      }

      &.active {
        background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsWoman_Hover.png');
      }
    }

    &:nth-of-type(3), &:nth-of-type(3):enabled:hover {
      background-image: url('@/assets/img/section-job/SectionJob_ButtonMageMan.png');
      &::before {
        content: '';
        background-image: url('@/assets/img/section-job/SectionJob_ButtonMageMan_Hover.png');
      }

      &.active {
        background-image: url('@/assets/img/section-job/SectionJob_ButtonMageMan_Hover.png');
      }
    }

    &:nth-of-type(4), &:nth-of-type(4):enabled:hover {
      background-image: url('@/assets/img/section-job/SectionJob_ButtonMageWoman.png');
      &::before {
        content: '';
        background-image: url('@/assets/img/section-job/SectionJob_ButtonMageWoman_Hover.png');
      }

      &.active {
        background-image: url('@/assets/img/section-job/SectionJob_ButtonMageWoman_Hover.png');
      }
    }

    &:nth-of-type(5), &:nth-of-type(5):enabled:hover {
      background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicMan.png');
      &::before {
        content: '';
        background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicMan_Hover.png');
      }

      &.active {
        background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicMan_Hover.png');
      }
    }

    &:nth-of-type(6), &:nth-of-type(6):enabled:hover {
      background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicWoman.png');
      &::before {
        content: '';
        background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicWoman_Hover.png');
      }

      &.active {
        background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicWoman_Hover.png');
      }
    }

    &:nth-of-type(7), &:nth-of-type(7):enabled:hover {
      background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingMan.png');
      &::before {
        content: '';
        background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingMan_Hover.png');
      }

      &.active {
        background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingMan_Hover.png');
      }
    }

    &:nth-of-type(8), &:nth-of-type(8):enabled:hover {
      background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingWoman.png');
      &::before {
        content: '';
        background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingWoman_Hover.png');
      }

      &.active {
        background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingWoman_Hover.png');
      }
    }
  }
}
</style>
