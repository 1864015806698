<template>
  <div id="header">
    <Menubar :model="items">
      <template #end>
        <ul>
          <li></li>
          <li @click="gotoContact()"></li>
          <li></li>
        </ul>
      </template>
    </Menubar>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  /* eslint-disable */
  name: 'Header',
  setup() {
    const items = ref([
      {
        label: 'トップ',
        command: () => {
          window.scrollTo(0,0)
        }
      },
      {
        label: '事前登錄',
        url: '#section-reserve-anchor'
      },
      {
        label: 'ワールド',
        url: '#section-map-anchor'
      },
      {
        label: 'キャラクター',
        url: '#section-job-anchor'
      },
      {
        label: 'システム',
        url: '#section-system-anchor'
      }
    ])

    const gotoContact = () => {
      window.open('https://twitter.com/Tenjinroku?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Eprofile%3ATenjinroku%7Ctwgr%5EeyJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3NlbnNpdGl2ZV9tZWRpYV9pbnRlcnN0aXRpYWxfMTM5NjMiOnsiYnVja2V0IjoiaW50ZXJzdGl0aWFsIiwidmVyc2lvbiI6bnVsbH0sInRmd190d2VldF9yZXN1bHRfbWlncmF0aW9uXzEzOTc5Ijp7ImJ1Y2tldCI6InR3ZWV0X3Jlc3VsdCIsInZlcnNpb24iOm51bGx9fQ%3D%3D&ref_url=https%3A%2F%2Fcreative-narwhal-fbb8fb.netlify.app%2F');
    }

    return { items, gotoContact }
  }
})
</script>

<style lang="scss" scoped>
  #header {
    position: fixed;
    width: 100%;
    height: fit-content;
    left: 0;
    top: 0;
    z-index: 99;
  }

  .p-menubar {
    border-radius: 0;
    border-width: 0;
  }

  @media screen and (max-width: 960px) {
    .p-menubar {
      flex-direction: row-reverse;
      background-color: rgba($color: #000000, $alpha: 0.8);
      height: 13.417vw;
      background: url('@/assets/img/header/NavBg.png') center/cover no-repeat;
    }
  }


</style>
