<template>
  <div class="section-post">
    <!-- <div class="bg">
      <img :src="require('@/assets/img/section-post/SectionPost_Background-2.png')" />
    </div> -->
    <div class="title"></div>
    <div class="post-frame">
      <div class="post-box">
        <ul>
          <li>
            <dl>
              <dt>
                <h5>fdgfadsfdsfsdfsdaffdsffsad</h5>
                <div class="date">2022-04-26</div>
              </dt>
              <dd>
                <p>
                  fdgfadsfdsfsdfsdaffdsffsad
                </p>
                <div class="button">
                  <button></button>
                </div>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>
                <h5>fdgfadsfdsfsdfsdaffdsffsad</h5>
                <div class="date">2022-04-26</div>
              </dt>
              <dd>
                <p>
                  fdgfadsfdsfsdfsdaffdsffsad
                </p>
                <div class="button">
                  <button></button>
                </div>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>
                <h5>fdgfadsfdsfsdfsdaffdsffsad</h5>
                <div class="date">2022-04-26</div>
              </dt>
              <dd>
                <p>
                  fdgfadsfdsfsdfsdaffdsffsad
                </p>
                <div class="button">
                  <button></button>
                </div>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>
                <h5>fdgfadsfdsfsdfsdaffdsffsad</h5>
                <div class="date">2022-04-26</div>
              </dt>
              <dd>
                <p>
                  fdgfadsfdsfsdfsdaffdsffsad
                </p>
                <div class="button">
                  <button></button>
                </div>
              </dd>
            </dl>
          </li>
        </ul>
      </div>
    </div>
    <div class="button list"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  /* eslint-disable */
  name: 'SectionPost'
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .section-post {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 9.224vw;

    // img {
    //   width: 100%;
    //   height: 100%;
    // }

    // .bg {
    //   position: absolute;
    //   width: 100%;
    //   height: auto;
    //   z-index: -1;
    //   left: 0;
    //   top: -10.787vw;
    // }

    .title {
      width: 90vw;
      height: 20.126vw;
      margin: 0 auto;
      background: url('@/assets/img/section-post/SectionPost_Title.png') center/contain no-repeat;
    }

    .post-frame {
      width: 76.730vw;
      height: 85.325vw;
      margin: 3.145vw auto 4.612vw;
      padding: 2.5vw 4vw;
      box-sizing: border-box;
      background: url('@/assets/img/section-post/SectionPost_Block.png') center/cover no-repeat;

      .post-box {
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        padding: 1.048vw 3.774vw;
        background-color: #fff;

        ul {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;

          li {
            width: 100%;

            dl {
              width: 100%;

              dt {
                border-bottom: 1px solid #dfeded;
                padding-top: 3.564vw;
                padding-bottom: 2.096vw;
                display: flex;
                justify-content: space-between;
                align-items: center;

                h5 {
                  color: #060010;
                  font-size: 2.516vw;
                  width: 42.558vw;
                  overflow : hidden;
                  text-overflow : ellipsis;
                  white-space : nowrap;
                  margin: 0;
                  text-align: left;
                }

                .date {
                  color: #000;
                  font-size: 1.887vw;
                }
              }

              dd {
                padding-top: 1.677vw;

                p {
                  width: 100%;
                  overflow : hidden;
                  text-overflow : ellipsis;
                  white-space : nowrap;
                  text-align: left;
                  font-size: 2.096vw;
                  margin-bottom: 3.774vw;
                }

                .button {
                  width: 100%;
                  text-align: right;

                  button {
                    width: 10.482vw;
                    height: 2.935vw;
                    box-sizing: border-box;
                    border: 1px solid #000;
                  }
                }
              }
            }
          }
        }
      }
    }

    .button.list {
      width: 36.688vw;
      height: 10.482vw;
      margin: 0 auto;
      background: url('@/assets/img/section-post/SectionPost_Button.png') center/contain no-repeat;
    }
  }
}
</style>
