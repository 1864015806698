<template>
  <div class="job-trans-ani">
    <div :class="[
      'trans-ani-box',
      charct.active ? 'active' : '',
      charct.fadeOut ? 'fadeOut' : '',
      charct.dir,
      charct.name
    ]">
      <div class="bg item"
        :style="{ background: 'url(' + require('@/assets/img/section-job/SectionJob_Button' + charct.name + 'BG.png') + ' ) center/contain no-repeat' }">
      </div>
      <div class="character item"
        :style="{ background: 'url(' + require('@/assets/img/section-job/SectionJob_Button' + charct.name + 'Character.png') + ' ) center bottom/contain no-repeat' }">
      </div>
      <div class="name item"
        :style="{ background: 'url(' + require('@/assets/img/section-job/SectionJob_Button' + charct.name + 'Text.png') + ' ) center/contain no-repeat' }">
      </div>
      <div class="play-btn item" @click="display = true"></div>
    </div>
  </div>
  <Galleria :value="[{ itemVideoSrc: charct.videoSrc }]" v-model:visible="display" :numVisible="1" :showThumbnails="false" :fullScreen="true" containerStyle="max-width: 99%">
    <template #item="slotProps">
      <iframe  width="560" height="315" :src="slotProps.item.itemVideoSrc" title="YouTube video player" autoplay
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
    </template>
  </Galleria>
  <i></i>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  /* eslint-disable */
  name: 'JobTransAni',
  props: {
    charct: {
      type: Object,
      default: () => { },
      required: true
    }
  },
  setup () {
    let show = ref(false)
    let display = ref(false)

    return { show, display }
  }
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .job-trans-ani {
    button {
      width: 82px;
      height: 20px;
      color: beige;
      background-color: red;
    }

    .trans-ani-box {
      position: relative;
      width: 100%;
      height: 100vw;
      overflow: hidden;

      .item {
        position: absolute;
        width: inherit;
        height: inherit;
      }

      img {
        width: 100%;
        height: auto;
      }

      .bg {
        top: 0;
        left: 0;
        opacity: 0;
        transition: 1s;
      }

      .character {
        top: 0;
        transition: 0.3s;
      }

      .name {
        transition: 0.3s;
      }

      .play-btn {
        width: 13.200vw;
        height: 13.467vw;
        bottom: 15vw;
        background: url('@/assets/img/section-job/SectionJob_Play.png') center/contain no-repeat;
        transition: 0.3s;
      }

      &.toLeft {
        .character {
          left: -100vw;
        }

        .name,
        .play-btn {
          left: 1000vw;
        }
      }

      &.toRight {
        .character {
          left: 100vw;
        }

        .name,
        .play-btn {
          left: -1000vw;
        }
      }

      // **腳色預設**
      &.SwordsMan {
        .name {
          width: 58.981vw;
          height: 42.037vw;
          bottom: 4.259vw;
        }
      }

      &.MageMan {
        .name {
          width: 64.815vw;
          height: 42.037vw;
          bottom: 4.352vw;
        }
      }

      &.SwordsWoman {
        .name {
          width: 74.259vw;
          height: 41.944vw;
          bottom: 4.259vw;
        }
      }

      &.MageWoman {
        .name {
          width: 92.593vw;
          height: 41.944vw;
          bottom: 4.352vw;
        }
      }

      &.MusicMan {
        .name {
          width: 64.074vw;
          height: 42.037vw;
          bottom: 4.259vw;
        }
      }

      &.FightingMan {
        .name {
          width: 91.667vw;
          height: 42.130vw;
          bottom: 4.259vw;
        }
      }

      &.MusicWoman {
        .name {
          width: 75.833vw;
          height: 42.037vw;
          bottom: 4.259vw;
        }
      }

      &.FightingWoman {
        .character {
          background-position-x: right !important;
        }

        .name {
          width: 75.648vw;
          height: 42.222vw;
          bottom: 4.259vw;
        }
      }

      // **進入腳色時**
      &.active {
        .item {
          opacity: 1;
        }

        .bg {
          opacity: 1;
        }

        .character {
          left: 0vw;
        }

        &.toLeft {
          .play-btn {
            left: 84vw;
          }
        }

        &.toRight {
          .play-btn {
            left: 4vw;
          }
        }

        &.SwordsMan {
          .name {
            left: 2.593vw;
          }
        }

        &.MageMan {
          .name {
            left: 2.593vw;
          }
        }

        &.SwordsWoman {
          .name {
            left: 23.148vw;
          }
        }

        &.MageWoman {
          .name {
            left: 4.722vw;
          }
        }

        &.MusicMan {
          .name {
            left: 2.593vw;
          }
        }

        &.FightingMan {
          .name {
            left: 2.407vw;
          }
        }

        &.MusicWoman {
          .name {
            left: 21.574vw;
          }
        }

        &.FightingWoman {
          .name {
            left: 21.574vw;
          }
        }
      }

      // **離開腳色時**
      &.fadeOut {
        .item {
          opacity: 0;
        }
      }
    }
  }
}

i {
  background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsManBG.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsManCharacter.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsManText.png');

  background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsWomanBG.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsWomanCharacter.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonSwordsWomanText.png');

  background-image: url('@/assets/img/section-job/SectionJob_ButtonMageManBG.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonMageManCharacter.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonMageManText.png');

  background-image: url('@/assets/img/section-job/SectionJob_ButtonMageWomanBG.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonMageWomanCharacter.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonMageWomanText.png');

  background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicManBG.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicManCharacter.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicManText.png');

  background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicWomanBG.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicWomanCharacter.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonMusicWomanText.png');

  background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingManBG.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingManCharacter.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingManText.png');

  background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingWomanBG.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingWomanCharacter.png');
  background-image: url('@/assets/img/section-job/SectionJob_ButtonFightingWomanText.png');
}
</style>
