<template>
  <div class="section-float">
    <figure :class="toggle ? 'toggle' : ''"></figure>
    <figcaption></figcaption>
    <div :class="['device', androidType ? 'android' : 'apple']"></div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  /* eslint-disable */
  name: 'SectionFloat',
  setup() {
    const toggle = ref(false)
    const device = ref(null)
    let androidType = ref(true)

    const getUserAgent = () => {
      device.value = navigator.userAgent.toLowerCase()
      // console.log('device =>', device.value)
      androidType.value = device.value.indexOf('android') > 0
    }

    onMounted(() => {
      getUserAgent()
      setInterval(() => {
        toggle.value = !toggle.value
      }, 3000)
    })

    return { device, androidType, toggle }
  }
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .section-float {
    position: fixed;
    background: url('@/assets/img/section-float/SectionFloat_Block.png') center/cover no-repeat;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 21.277vw;
    z-index: 9;
    overflow: visible;

    figure {
      position: absolute;
      left: 0.236vw;
      bottom: 0;
      width: 39.007vw;
      height: 45.81vw;
      background: url('@/assets/img/section-float/SectionFloat_Cat.png') center/cover no-repeat;

      &.toggle {
        animation: shake 0.5s;
        animation-timing-function: linear;

        /* When the animation is finished, start again */
        // animation-iteration-count: infinite;
      }
    }

    @keyframes shake {
      0% { transform: translate(0px, 0px); }
      25% { transform: translate(0px, 10px); }
      50% { transform: translate(0px, 0px); }
      75% { transform: translate(0px, 10px); }
      100% { transform: translate(0px, 0px); }
    }

    figcaption {
      position: absolute;
      bottom: 2.128vw;
      left: 2.128vw;
      width: 36.643vw;
      height: 7.329vw;
      background: url('@/assets/img/section-float/SectionFloat_Title.png') center/contain no-repeat;
    }

    .device {
      position: absolute;
      bottom: 0.676vw;
      right: 24.823vw;
      width: 30.733vw;
      height: 8.747vw;

      &.android {
        background: url('@/assets/img/section-float/SectionFloat_GoogleLogin.png') center/contain no-repeat;
      }

      &.apple {
        background: url('@/assets/img/section-float/SectionFloat_IosLogin.png') center/contain no-repeat;
      }
    }
  }
}
</style>
