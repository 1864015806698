<template>
  <div class="section-cover">
    <img :src="require('@/assets/img/section-cover/SectionCover_Background-2.jpg')" />
    <!-- <h1>
      <div class="title"></div>
    </h1>
    <h2></h2>
    <h3></h3> -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  /* eslint-disable */
  name: 'SectionCover'
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .section-cover {
    position: relative;
    // padding-bottom: 9.224vw;
    margin-bottom: -5.1vw;
    height: 192vw;

    img {
      width: 100%;
      height: 100%;
    }

    h1 {
      position: absolute;
      left: 0;
      bottom: 46vw;
      width: 98.952vw;
      height: 24.109vw;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      background: url('@/assets/img/section-cover/SectionCover_TitleBrush.png') center/contain no-repeat;

      .title {
        margin-top: -2.8vw;
        width: 70.440vw;
        height: 25.367vw;
        background: url('@/assets/img/section-cover/SectionCover_TitleWord-2.png') center/contain no-repeat;
      }
    }
    
    h2 {
      position: absolute;
      left: 0;
      bottom: 40.2vw;
      width: 100%;
      height: 7.128vw;
      margin: 0;
      background: url('@/assets/img/section-cover/SectionCover_SubTitle2.png') center/cover no-repeat;
    }

    h3 {
      position: absolute;
      left: 50%;
      bottom: 29.15vw;
      width: 80.713vw;
      height: 11.321vw;
      margin: 0;
      margin-left: calc(-80.713vw / 2);
      background: url('@/assets/img/section-cover/SectionCover_SubTitle1.png') center/contain no-repeat;
    }
  }
}
</style>
