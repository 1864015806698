<template>
  <div id="home">
    <SectiionFloat />
    <SectionCover />
    <SectionReserve />
    <SectionStory />
    <SectionMap />
    <SectionJob />
    <SectionSystem />
    <div class="stable-bg">
      <SectionPost v-if="showPost"/>
      <SectionTwitter />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import SectiionFloat from '@/components/SectionFloat.vue'
import SectionCover from '@/components/SectionCover.vue'
import SectionReserve from '@/components/SectionReserve.vue'
import SectionPost from '@/components/SectionPost.vue'
import SectionTwitter from '@/components/SectionTwitter.vue'
import SectionJob from '@/components/SectionJob.vue'
import SectionStory from '@/components/SectionStory.vue'
import SectionMap from '@/components/SectionMap.vue'
import SectionSystem from '@/components/SectionSystem.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    SectiionFloat,
    SectionCover,
    SectionReserve,
    SectionPost,
    SectionTwitter,
    SectionJob,
    SectionStory,
    SectionMap,
    SectionSystem
  },
  setup () {
    const showPost = ref(null)

    onMounted(() => {
      showPost.value = !!Number(process.env.VUE_APP_SHOW_SECTION_POST)
    })

    return {
      showPost
    }
  }
})
</script>

<style lang="scss" scoped>
  #home {
    .stable-bg {
      width: 100%;
      height: auto;
      z-index: -1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
      background-image: url('@/assets/img/section-twitter/SectionTwitter_Background.png');
    }
  }
</style>
